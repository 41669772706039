import React from 'react';
import Product from '../../models/Product';
import ProductBlock from '../ProductBlock';
import Left from '../SlickArrow/Left';
import Right from '../SlickArrow/Right';
import Slider from 'react-slick';
import './styles/Recommendations.scss';
import { gql, useQuery } from '@apollo/client';

const GET_LATEST = gql`
  query LatestProducts($slug: String) {
    latestProducts(slug: $slug) {
      id
      title
      slug
      brands {
        id
        title
      }
      prices {
        type
        value
      }
      categories {
        gridTitle
        id
      }
      cover {
        cdn_url
        urls {
          W100
          W1000
          W1600
          W300
          W356
          W435
          W540
          W800
          W890
        }
      }
    }
  }
`;
type ProductItem = {
  title: string;
  slug: string;
  id: string;
  brand: {
    id: string;
    title: string;
  };
  categories: {
    id: string;
    gridTitle: string;
  }[];
  prices: { type: string; value: number };
  cover: {
    cdn_url: string;
    urls: {
      W100: string;
      W1000: string;
      W1600: string;
      W300: string;
      W356: string;
      W435: string;
      W540: string;
      W800: string;
      W890: string;
    };
  };
};
interface Props {
  productSlug?: string;
  title?: React.ReactNode;
}
const Latest = (props: Props) => {
  const { productSlug, title } = props;
  const { data } = useQuery<{
    latestProducts: ProductItem[];
  }>(GET_LATEST, {
    variables: { slug: productSlug },
  });

  const recommendations =
    data?.latestProducts.map((product) => new Product(product)) || [];

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    // dotsClass: 'awesome-dots',
    customPaging: () => {
      return <span />;
    },
    prevArrow: <Left />,
    nextArrow: <Right />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const productElements = recommendations.map((product) => (
    <ProductBlock key={product.slug} product={product} slider={true} />
  ));

  return (
    <div className="recommendations-block">
      <h2>{title}</h2>
      <div className="products-slick">
        <Slider {...settings}>{productElements}</Slider>
      </div>
    </div>
  );
};

export default Latest;
