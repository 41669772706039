import React, { Fragment, useState } from 'react';
import ProductModel from '../../models/Product';
import Price from '../Price/Price';
import Image from '../Image';
import { Sizes } from '../../constants/Image';
import LocalizedLink from '../LocalizedLink';
import { gql, useMutation } from '@apollo/client';
import { Dialog, Transition } from '@headlessui/react';
import ThankyouModal from './ThankyouModal';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Translate from '../Translate/Translate';

type Props = {
  product: ProductModel;
  variation;
  id;
  selectedBike;
};
const SEND = gql`
  mutation Mutation(
    $createPreorderFirstname: String!
    $createPreorderLastname: String!
    $createPreorderEmail: String!
    $createPreorderPhone: String!
    $createPreorderExtraInformation: String
    $createPreorderVariationDescription: String
    $createPreorderVariationId: ID
  ) {
    createPreorder(
      firstname: $createPreorderFirstname
      lastname: $createPreorderLastname
      email: $createPreorderEmail
      phone: $createPreorderPhone
      extraInformation: $createPreorderExtraInformation
      variationDescription: $createPreorderVariationDescription
      variationId: $createPreorderVariationId
    ) {
      createdAt
    }
  }
`;
export default function OrderBikeModal({
  product,
  variation,
  id,
  selectedBike,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);

  function close() {
    setIsOpen(false);
  }

  function open() {
    setIsOpen(true);
  }
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    info: '',
  });
  const [createPreorder] = useMutation(SEND);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await createPreorder({
        variables: {
          createPreorderFirstname: formData.firstName,
          createPreorderLastname: formData.lastName,
          createPreorderEmail: formData.email,
          createPreorderPhone: formData.phone,
          createPreorderExtraInformation: formData.info,
          createPreorderVariationDescription: variation,
          createPreorderVariationId: id,
        },
      });
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        info: '',
      });
      setOpenModal(true);
      setTimeout(() => {
        {
          close();
          setOpenModal(false);
        }
      }, 3000);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const heightMeta = selectedBike?.meta.find(
    (metaItem) => metaItem.meta_key === 'bicycle_height',
  );

  return (
    <>
      <button
        type="button"
        onClick={open}
        className="w-80 text-white bg-rviolet tracking-wide font-axi-bold flex items-center justify-center rounded flex-auto px-3.5 py-4 cursor-pointer "
      >
        <Translate component="user-orders" keyword="pre-order" />
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[100] w-full h-full"
          onClose={close}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="flex justify-center overflow-auto md:min-h-full center overflow-y-auto">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="fixed overflow-y-auto top-5 bottom-5 rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl">
                <div className="absolute right-0 top-0  pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                    onClick={close}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="">
                  <div className="flex mt-3 text-center ">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900 ml-5"
                    >
                      <Translate component="user-orders" keyword="make-order" />
                    </Dialog.Title>
                  </div>
                </div>

                <div className="md:w-[700px] m-5">
                  {openModal && <ThankyouModal />}
                  <div className="grid md:grid-cols-2 gap-4">
                    <div className="w-80">
                      <Image
                        className="rounded bg-rgrey-lightlight object-cover w-32 md:w-52 h-auto"
                        defaultSize={Sizes.W800}
                        image={product.images[0]}
                        allSizes={[Sizes.W300, Sizes.W540, Sizes.W800]}
                      />
                    </div>
                    <div className="w-full text-left">
                      <h3 className="whitespace-nowrap my-5 text-2xl leading-8 font-axi-bold">
                        {product.title}
                      </h3>
                      <div className="border rounded text-center w-40 my-5 leading-6 py-3 px-4">
                        {heightMeta
                          ? variation + ' (' + heightMeta.meta_value + ')'
                          : variation}
                      </div>
                      <div className="">
                        <div className="flex gap-x-24">
                          <p className="capitalize leading-6">soodushind</p>
                          <p className="capitalize leading-6">tavahind</p>
                        </div>
                        <Price
                          prices={product.prices(true)}
                          priceLabel={true}
                          discountPriceClassname="text-4xl font-normal mr-5"
                          defaultPriceClassName="text-lg text-nowrap"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="my-5">
                    <h4 className="leading-6">
                      <Translate
                        component="checkout"
                        keyword="parcel-additional-info"
                      />
                    </h4>
                    <p className="leading-6">
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Hic error quam, recusandae incidunt id perferendis
                      sapiente explicabo, voluptatibus qui accusamus autem
                      dolores reprehenderit, iste sit fugiat voluptates eum!
                      Recusandae, ducimus!{' '}
                      <a
                        className="text-rgreen"
                        href="https://www.trekbikes.com/us/en_US/"
                      >
                        Treki koduleht
                      </a>
                    </p>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <h4>
                      <Translate component="checkout" keyword="my-data" />:
                    </h4>

                    <div className="grid grid-cols-2 gap-x-2 gap-y-4 sm:grid-cols-6">
                      <div className="sm:col-span-3">
                        <label
                          htmlFor="firstName"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          <Translate component="checkout" keyword="firstname" />
                        </label>
                        <div className="mt-2">
                          <input
                            required
                            type="text"
                            value={formData.firstName}
                            onChange={handleChange}
                            name="firstName"
                            id="firstName"
                            className="block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="lastName"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          <Translate component="checkout" keyword="lastname" />
                        </label>
                        <div className="mt-2">
                          <input
                            required
                            type="text"
                            value={formData.lastName}
                            onChange={handleChange}
                            name="lastName"
                            id="lastName"
                            className="block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          <Translate component="checkout" keyword="phone" />
                        </label>
                        <div className="mt-2">
                          <input
                            required
                            type="text"
                            value={formData.email}
                            onChange={handleChange}
                            name="email"
                            id="email"
                            className="block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="phone"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          <Translate component="checkout" keyword="email" />
                        </label>
                        <div className="mt-2">
                          <input
                            required
                            type="text"
                            value={formData.phone}
                            onChange={handleChange}
                            name="phone"
                            id="phone"
                            className="block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="sm:col-span-3 mt-4">
                      <label
                        htmlFor="info"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        <Translate
                          component="checkout"
                          keyword="parcel-additional-info"
                        />
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          value={formData.info}
                          onChange={handleChange}
                          name="info"
                          id="info"
                          className="block w-full h-20 rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="mt-5 grid grid-cols-2 gap-x-2 gap-y-4 sm:grid-cols-6 items-center">
                      <div className="sm:col-span-3 flex items-center">
                        <input
                          required
                          type="checkbox"
                          className="h-8 w-8 rounded shrink-0 border-rgrey-light text-rgreen focus:ring-rgreen cursor-pointer mr-5"
                        />
                        <p className="underline">
                          <LocalizedLink to={'/tingimused'}>
                            <Translate component="checkout" keyword="terms" />
                          </LocalizedLink>
                        </p>
                      </div>
                      <button
                        type="submit"
                        className="sm:col-span-3 block w-full h-12 leading-6 font-axi-bold rounded-md border-rgrey-light text-white bg-rviolet cursor-pointer focus:ring-2 focus:ring-violet-200"
                      >
                        <Translate
                          component="user-orders"
                          keyword="place-order"
                        />
                      </button>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
