import React from 'react';
import { useLocation } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import LocalizedLink from '../LocalizedLink';
import Translate from '../Translate/Translate';

const GET_PATH = gql`
  query GetPath($slug: String!) {
    path(slug: $slug) {
      slug
      name
    }
  }
`;

interface BreadcrumbsProps {
  slug: string;
}

const Breadcrumbs = ({ slug }: BreadcrumbsProps) => {
  const { data, previousData } = useQuery(GET_PATH, { variables: { slug } });
  const path = data?.path || previousData?.path || [];

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  queryParams.delete('page');

  const crumbs = [];
  path.forEach((pathItem, index) => {
    const categorySlug = index === 0 ? '/tooted' : '';
    crumbs.push({
      name: pathItem.name,
      path: `${index > 0 ? crumbs[index - 1].path : ''}${categorySlug}/${
        pathItem.slug
      }`,
    });
  });

  return (
    <div className="mt-5 mb-2.5 w-full flex items-center overflow-y-hidden overflow-x-scroll whitespace-nowrap z-0 md:overflow-y-visible md:overflow-x-visible md:whitespace-normal m-0">
      <LocalizedLink
        to="/"
        className="flex text-[#9d9d9d] text-sm no-underline mr-1.5 mb-2.5 last:text-black last:underline  after:ml-1.5 hover:text-rgreen after:content-['_-_'] last:after:content-['']"
      >
        <Translate component="breadcrumbs" keyword="avaleht" />
      </LocalizedLink>
      {crumbs.map((crumb) => {
        return (
          <LocalizedLink
            to={`${crumb.path}?${queryParams}`}
            key={crumb.path}
            className="flex text-[#9d9d9d] text-sm no-underline mr-1.5 mb-2.5 last:text-black last:underline  after:ml-1.5 hover:text-rgreen after:content-['_-_'] last:after:content-['']"
          >
            {crumb.name}
          </LocalizedLink>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
