import React from 'react';
import { gql, useQuery } from '@apollo/client';

import Translate from '../Translate/Translate';
import Image from '../Image';
import LocalizedLink from '../LocalizedLink';
import { Sizes } from '../../constants/Image';
import CheckedIcon from '../../images/checked_w.svg';
import { Tooltip } from 'react-tooltip';

const GET_RELATIONS = gql`
  query ProductRelations($slug: String) {
    productRelations(slug: $slug) {
      id
      slug
      title
      media {
        urls {
          W100
        }
      }
      colours {
        id
        slug
        title
      }
    }
  }
`;

interface Props {
  productSlug?: string;
}

const Related = ({ productSlug }: Props) => {
  const { data, loading } = useQuery(GET_RELATIONS, {
    variables: { slug: productSlug },
  });

  if (!data || loading) {
    return null;
  }

  const products = data.productRelations.map((product) => {
    const className =
      product.slug === productSlug
        ? ' w-[70px] h-[70px] relative border-2 border-black rounded'
        : 'w-[70px] h-[70px]';

    const cover = product.media?.length ? product.media[0] : null;
    if (!cover) {
      return null;
    }

    const colourTitle =
      product.colours?.length > 0 ? product.colours[0].title : '';

    return (
      <div
        className={className}
        key={product.slug}
        data-tooltip-id="colourTitle"
        data-tooltip-content={colourTitle}
      >
        <LocalizedLink to={`/toode/${product.slug}`}>
          <Image
            defaultSize={Sizes.W100}
            image={cover}
            allSizes={[Sizes.W100]}
            className={'rounded'}
            alt={colourTitle}
          />
          {product.slug === productSlug ? (
            <div className="absolute w-5 h-5 -bottom-[10px] bg-black rounded-full right-[36%]">
              <img className="p-1 mt-0.5" src={CheckedIcon} alt="checked" />
            </div>
          ) : null}
        </LocalizedLink>
      </div>
    );
  });

  const showColors = data.productRelations.length > 1;

  return showColors ? (
    <div>
      <h5 className="h5">
        <Translate component="product" keyword="colours" />
      </h5>
      <div className="flex flex-wrap gap-1 mt-[10px] rounded">{products}</div>
      <Tooltip id="colourTitle" />
    </div>
  ) : null;
};

export default Related;
