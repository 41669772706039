import React from 'react';
import Translate from '../Translate/Translate';
import Modal from '../Modal';
import { gql, useQuery } from '@apollo/client';

const GET_SIZES = gql`
  query ProductSizes($slug: String) {
    productSizes(slug: $slug) {
      header
      sizes {
        size
        sizeChart {
          sizeValue
          sizeType
          orderColumn
        }
      }
    }
  }
`;
type SizeItem = {
  header: string[];
  sizes: {
    size: string;
    sizeChart: { sizeValue: string; sizeType: string; orderColumn: number }[];
  }[];
};
interface Props {
  productSlug?: string;
}

const SizeChart = (props: Props) => {
  const { productSlug } = props;
  const { data: sizesData } = useQuery<{
    productSizes: SizeItem;
  }>(GET_SIZES, {
    variables: { slug: productSlug },
  });

  if (!sizesData || sizesData.productSizes.sizes === null) {
    return null;
  }
  const { header, sizes } = sizesData?.productSizes;
  const sizeChart = (
    <div className="size-chart max-h-[500px] min-w-72 overflow-auto">
      <table className="text-sm table-fixed w-full border-collapse">
        <thead className="">
          <tr className="">
            {header?.map((th, index) => (
              <th
                key={th}
                className={`border text-center align-top py-2.5 border-solid border-rgrey-light ${index === 1 ? 'bg-rgrey-lightborder' : ''}`}
              >
                <Translate component="size-chart" keyword={th} />
              </th>
            ))}
          </tr>
        </thead>

        <tbody className="overflow-auto">
          {sizes?.map((sizeItem) => (
            <tr key={sizeItem.size}>
              {sizeItem.sizeChart.map((size, index) => (
                <td
                  className={`border text-center align-top py-2.5 border-solid border-rgrey-light ${index === 1 ? 'bg-rgrey-lightborder' : ''}`}
                  key={`${size.sizeType}-${size.sizeValue}`}
                >
                  {size.sizeValue}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <Modal
      buttonClass="table hover:underline cursor-pointer"
      button={<Translate component="product" keyword="size-chart" />}
      content={sizeChart}
      contentTitle={<Translate component="product" keyword="sizes-chart" />}
      modalClass="center overflow-auto"
    />
  );
};

export default SizeChart;
