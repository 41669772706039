import React from 'react';
import Translate from '../Translate/Translate';
import { observer } from 'mobx-react';
import { gql, useQuery } from '@apollo/client';
import Loader from '../Loader/Loader';
import './styles/Availability.scss';

const GET_SHOPS = gql`
  query PublicShops {
    publicShops {
      id
      title
      slug
      city
      citySlug
    }
  }
`;
type ShopItem = {
  id: string;
  title: string;
  slug: string;
  city: string;
  info: string;
  citySlug: string;
  hasMaintenance: boolean;
};

type Quantity = {
  quantity: number;
  shop_id: number;
  variation: {
    code: string;
  };
};

type Variation = {
  value: string;
  code: string;
};

interface Props {
  quantities: Quantity[];
  variations: Variation[];
}

const Availability: React.FC<Props> = observer(({ quantities, variations }) => {
  const { loading, error, data } = useQuery<{
    publicShops: ShopItem[];
  }>(GET_SHOPS);

  const shops = data?.publicShops;

  if (loading) {
    return (
      <div className="flex justify-center m-10">
        <Loader />
      </div>
    );
  }

  if (error) {
    return <div className="m-5">Error: {error.message}</div>;
  }

  const shopVariations = (
    selectedShopId: number | undefined,
    title: string,
    last: boolean,
  ) => {
    let allShopIds = shops.map((shop) => parseInt(shop.id));
    if (selectedShopId) {
      allShopIds = allShopIds.filter((shopId) => shopId === selectedShopId);
    }
    let hasAvailability = false;

    if (!allShopIds.length) {
      return null;
    }

    const availabilities = variations.map((v) => {
      const quantity = quantities.find(
        (q) =>
          (allShopIds.includes(q.shop_id) || selectedShopId === undefined) &&
          q.variation.code === v.code &&
          q.quantity === 1,
      );

      let check = null;
      if (quantity) {
        check = <span />;
        hasAvailability = true;
      }
      return (
        <td className="variation" key={v.code}>
          {check}
        </td>
      );
    });

    if (!hasAvailability) {
      return null;
    }

    return (
      // yhe poe kohta rida
      <tr key={allShopIds.join()} className={last ? 'shop last' : 'shop'}>
        <td className="shop-title">
          {/* poe nimi */}
          <div>{title}</div>
        </td>
        {availabilities}
      </tr>
    );
  };

  const variationsHeader = variations.map((variation) => (
    <th className="header" key={variation.code}>
      {/* Suurused */}

      {variation.value}
    </th>
  ));

  const rocca = shopVariations(3, 'Rocca al Mare', false);
  const ylemiste = shopVariations(15, 'Ülemiste', false);
  const kristiine = shopVariations(14, 'Kristiine', false);
  const jarve = shopVariations(4, 'Järve', false);
  const t1 = shopVariations(21, 'T1', false);
  const nautica = shopVariations(19, 'Nautica', false);
  const magistral = shopVariations(1, 'Magistral', true);
  const inTallinn =
    rocca || ylemiste || kristiine || jarve || t1 || nautica || magistral;

  const lounakas = shopVariations(6, 'Lõunakeskus', false);
  const kvartal = shopVariations(7, 'Kvartal', false);
  const eeden = shopVariations(17, 'Eeden', true);
  const inTartu = lounakas || kvartal || eeden;

  const kaubamajakas = shopVariations(2, 'Kaubamajakas', false);
  const portartur = shopVariations(9, 'Port Artur 2', true);
  const inParnu = kaubamajakas || portartur;

  const pohjakeskus = shopVariations(12, 'Põhjakeskus', true);
  const inRakvere = pohjakeskus;

  const fama = shopVariations(5, 'Fama', true);
  const inNarva = fama;

  const pargi = shopVariations(11, 'Pargi', true);
  const inJohvi = pargi;

  const centrum = shopVariations(8, 'Centrum', true);
  const inViljandi = centrum;

  const auriga = shopVariations(13, 'Auriga', true);
  const inKuressaare = auriga;

  const rannarootsi = shopVariations(10, 'Rannarootsi', true);
  const inHaapsalu = rannarootsi;

  return (
    <div className="availability max-h-[500px] mb-2" style={{ width: '100%' }}>
      <table>
        <thead>
          <tr>
            <th className="header sizes">
              {/* Pealkiri - suurused */}
              <Translate component="availability" keyword="sizes" />
            </th>
            {variationsHeader}
          </tr>
        </thead>
        <tbody>
          <>
            {shopVariations(undefined, 'E-pood', false)}
            {inTallinn && (
              <tr className="city">
                <td>Tallinn</td>
              </tr>
            )}
            {shopVariations(3, 'Rocca al Mare', false)}
            {shopVariations(15, 'Ülemiste', false)}
            {shopVariations(14, 'Kristiine', false)}
            {shopVariations(4, 'Järve', false)}
            {shopVariations(21, 'T1', false)}
            {shopVariations(19, 'Nautica', false)}
            {shopVariations(1, 'Magistral', true)}
            {inTartu && (
              <tr className="city">
                <td>Tartu</td>
              </tr>
            )}
            {shopVariations(6, 'Lõunakeskus', false)}
            {shopVariations(7, 'Kvartal', false)}
            {shopVariations(17, 'Eeden', true)}
            {inParnu && (
              <tr className="city">
                <td>Pärnu</td>
              </tr>
            )}
            {shopVariations(2, 'Kaubamajakas', false)}
            {shopVariations(9, 'Port Artur 2', true)}
            {inRakvere && (
              <tr className="city">
                <td>Rakvere</td>
              </tr>
            )}
            {shopVariations(12, 'Põhjakeskus', true)}
            {inNarva && (
              <tr className="city">
                <td>Narva</td>
              </tr>
            )}
            {shopVariations(5, 'Fama', true)}
            {inJohvi && (
              <tr className="city">
                <td>Jõhvi</td>
              </tr>
            )}
            {shopVariations(11, 'Pargi', true)}
            {inViljandi && (
              <tr className="city">
                <td>Viljandi</td>
              </tr>
            )}
            {shopVariations(8, 'Centrum', true)}
            {inKuressaare && (
              <tr className="city">
                <td>Kuressaare</td>
              </tr>
            )}
            {shopVariations(13, 'Auriga', true)}
            {inHaapsalu && (
              <tr className="city">
                <td>Haapsalu</td>
              </tr>
            )}
            {shopVariations(10, 'Rannarootsi', true)}
          </>
        </tbody>
      </table>
    </div>
  );
});

export default Availability;
