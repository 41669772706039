import React from 'react';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import Translate from '../Translate/Translate';

export default function Header({ currentCategory, goBackClick }) {
  let breadcrumbs = null;
  if (currentCategory) {
    breadcrumbs = <Breadcrumbs slug={currentCategory} />;
  }
  return (
    <div className="items-center flex justify-start relative">
      <p
        className="flex text-sm whitespace-nowrap mr-6 hover:text-rgreen hover:cursor-pointer"
        onClick={goBackClick}
      >
        <ChevronLeftIcon className="mr-2 h-5 w-5" />
        <Translate component="product" keyword="back" />
      </p>
      {breadcrumbs}
    </div>
  );
}
