declare const fbq;

export const track = (title, data) => {
  fbq('track', title, data);
};

export const purchase = (cart, auth) => {
  const cartRows = cart.variations.map((variation) => {
    const product = cart.getProduct(variation.product_id);

    return {
      id: product.data.id,
      quantity: variation.quantity,
      item_price: product.finalPrice(auth),
    };
  });

  fbq('track', 'Purchase', {
    value: cart.totalSum,
    currency: 'EUR',
    contents: cartRows,
    content_type: 'product',
  });
};
