import React from 'react';
import { observable, action, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import parse from 'html-react-parser';
import './styles/TechnologyItem.scss';

interface Props {
  data?: {
    id: number;
    title: string;
    description: string;
  };
}

@observer
export default class TechnologyItem extends React.Component<Props> {
  @observable toggleContent = false;

  constructor(props) {
    super(props);
    makeObservable(this);
    this.setToggleContent = this.setToggleContent.bind(this);
  }

  @action setToggleContent() {
    this.toggleContent = !this.toggleContent;
  }

  render() {
    const { data } = this.props;
    return (
      <div
        className={`component-technologyItem${
          this.toggleContent ? ' open' : null
        }`}
      >
        <div
          onClick={this.setToggleContent}
          className="component-technologyItem__header"
        >
          <h6>{data.title}</h6>
        </div>
        {this.toggleContent ? (
          <div className="component-technologyItem__content">
            <p>{parse(data.description)}</p>
          </div>
        ) : null}
      </div>
    );
  }
}
